import React, {useEffect, useState} from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import ContentWrapper from '../components/content-wrapper/content-wrapper';
import SEO from '../components/seo/seo';

const ChooseSeatsPage = ({ data }) => {
  // Create state for eventId variable.
  const [eventId, setEventId] = useState(null);

  // Pull in all parameters from URL to parse out the performance (eventId).
  useEffect(() => {
    var pageUrl = window.location.search.substring(1);
    var urlVariables = pageUrl.split('&');
    for (var i = 0; i < urlVariables.length; i++) {
      var parameterName = urlVariables[i].split('=');
      if (parameterName[0] === 'performance') {
        // Set eventId to the "performance" URL parameter.
        setEventId(parameterName[1]);
      }
    }
  }, []);

  return (
    <DefaultLayout data={data}>
      <SEO
        title="Choose Seats"
        description="Choose your seats for the event."
        siteConfig={data.site.siteMetadata}
        pageUrl="/choose-seats"
      />
      <ContentWrapper>
        <iframe
          title='Choose Seats'
          name='SpektrixIFrame'
          id='SpektrixIFrame'
          frameBorder='0'
          onLoad={(event)=> event.target.className = 'loaded'}
          src={`${ data.site.siteMetadata.spektrixAPI }/${data.site.siteMetadata.spektrixClientName}/website/ChooseSeats.aspx?resize=true&EventInstanceId=${eventId}`}>
        </iframe>
      </ContentWrapper>
    </DefaultLayout>
  );
};

ChooseSeatsPage.propTypes = {
  data: PropTypes.object
};

export default ChooseSeatsPage;

export const query = graphql`
  query ChooseSeatsPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
  }
`;
